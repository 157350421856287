import { Component, Input, OnInit, OnChanges, OnDestroy, Renderer2, ViewEncapsulation } from '@angular/core';

import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';

import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../auth/auth.service';
import { SessionService } from '../session/session.service';
import { Session } from '../session/session.model';
import { Users } from '../users/users.model';

import { VgAPI } from 'videogular2/core';


@Component({
  selector: 'app-admin-session',
  templateUrl: './admin-session.component.html',
  styleUrls: ['./admin-session.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AdminSessionComponent implements OnInit {

  public sessionID: string = '';
  public userCount: number;
  public hideStartForever: string = '';

  sessionList: Session[];
  sessionItem: AngularFireList<any>;
  usersList: Users[];
  userItem: AngularFireList<any>;

   preload:string = 'auto';
   api:VgAPI;

   modalClosed:number = 0;

   responseRef: AngularFireList<any>;

  constructor(public db: AngularFireDatabase,public router: Router, route: ActivatedRoute, public authService: AuthService, private sessionService: SessionService, private renderer:Renderer2) {

    this.renderer.addClass(document.body, 'admin-session');
    this.hideStartForever = '0';
    if(localStorage.getItem('hideStart')){
      this.hideStartForever = localStorage.getItem('hideStart');
    }
    console.log(this.hideStartForever);

    route.params.forEach((urlParameters) => {
     	this.sessionID = urlParameters['id'];
     	
     	//console.log(this.sessionID);
     });

    this.responseRef = db.list('/responses');

    this.db.list('/users', ref=> ref.orderByChild('session').equalTo(this.sessionID)).valueChanges().subscribe(queriedItems => {
			this.userCount = queriedItems.length;
	});
  }

  ngOnInit() {
  	this.sessionItem = this.sessionService.getData();
  	this.userItem = this.sessionService.removeUserDataFromSession(this.sessionID);

  	//console.log(this.userItem);

  	var x = this.sessionService.getData();
    //console.log(x);
    x.snapshotChanges().subscribe(item=>{
      this.sessionList = [];
      item.forEach(element => {
         var y = element.payload.toJSON();
         y["$key"] = element.key;
         this.sessionList.push(y as Session);
      });
    });
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'admin-session');
  }

  hideStartModal(){
    var modal = document.getElementById('startModal');
    modal.classList.add("hidden");
    setTimeout(function(){
      modal.style.display = "none";
    },1000)
 
    this.hideStartForever = '1';

    localStorage.setItem('hideStart', this.hideStartForever);

  }

  showStartModal(){
    this.hideStartForever = '0';
    localStorage.setItem('hideStart', this.hideStartForever);

    var modal = document.getElementById('startModal');

    setTimeout(function(){
      modal.style.display = "block";
    },1000)

    //this.hideStartForever = '1';
  }

  launchSession(course,location) {
    //console.log(course);
    //console.log(location);

    this.router.navigateByUrl( '/admin/'+location+'/'+course , { skipLocationChange:false } );

  }

  onPlayerReady(api:VgAPI) {
      this.api = api;

      this.api.play();

      //console.log(this.api);
  }

  logout(session) {
  	if(session == undefined){
  		//this.session_ID = '';
  	}else{
  		//this.session_ID = '';
  	}
  	this.sessionItem.update(session,{ admin:'',isActive:false });
  	//this.sessionService.updateSessionLogout(session);
  	this.userItem.snapshotChanges().subscribe(item=>{
  		item.forEach(element => {
  			var y = element.payload.toJSON();
  			y["$key"] = element.key;
  			this.db.list('users').remove(y["$key"]);
  		})
  	});
  }

  exit(session){
    this.modalClosed = 1;
  }

  cancelExit(session){
    this.modalClosed = 0;
  }

  confirmExit(session){
    localStorage.setItem('hideStart', '0');
    this.sessionItem.update(session,{ isActive:false, hasSessionEnded:true });
      
    this.authService.adminUserSignOut();
  }

}
